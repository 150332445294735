.initial-swiper {
    width: 100%;
    height: 250px;
    margin-top: 2%;
    margin-bottom: 2%;
    overflow: unset !important;
}

.initial-swiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 30%;
}

.initial-swiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 20px #00000080,0 -1px #ffffff80;
    border-radius: 16px;
    box-sizing: border-box;
}

.initial-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
}

.initial-container h1 {
    margin: 0;
    color: white;
    text-shadow: 3px 4px black;
}

.country-slider-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px;
    box-sizing: border-box;
    color: #fff;
    text-shadow: 1px 1px 1px #000;
    line-height: 1.25;
    border-radius: 0 0 16px 16px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0));
    font-size: 32px;
}

.swiper-button-prev {
    z-index: 999;
}

.swiper-button-next{
    z-index: 999;
}