.option-background {
    width: 100vw;
    height: 30vh;
    position: fixed;
    bottom: 0;
    background: rgb(0, 0, 0, 0.7);
}

.question-top {
    font-family: Georgia;
    width: 100vw;
    height: 30vh;
    position: fixed;
    top: 0;
    background: linear-gradient(to bottom, black, transparent 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 3vw;
}

.option-background-col {
    display: flex;
    align-items: center;
    justify-content: center;
}