.game-background {
  height: 100vh;
  width: 100vw;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  position: relative;
  overflow: hidden;
}


.library-ongoing-container {
  position: absolute;
  width: 80vw;
  background-color: transparent;
  height: 80vh;
  left: 10vw;
  top: 10vh;
}