nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 999;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
}

.menu-btn {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
}

.sidebar-ul,
.sidebar-ul li {
    margin: 0;
    padding: 0;
}

.sidebar-ul {
    padding: 25px;
    position: absolute;
    top: 100px;
    width: 230px;
}

.sidebar-ul li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
}

.icon-placeholder img {
    width: 100%;
    height: 100%;
}

.text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
}

.refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}