.game-answer {
    height: 100%;
}

.game-answer-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.summary-content {
    position: absolute;
    top: 20%;
    height: 50%;
    width: 100%;
    text-align: center;
}

.summary-content img {
    height: 50%;
}

.summary-content h1 {
    margin: 0;
    font-size: 3vw;
}

.summary-content h2 {
    margin: 0;
    font-size: 1.5vw;
}

.summary-footer{
    height: 20%;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.summary-footer-col{
    display: flex;
    align-items: center;
    justify-content: center;
}