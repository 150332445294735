.content {
    text-align: center;
}

.content h1 {
    margin: 0;
    font-size: 4em;
    color: white;
    text-shadow: 3px 4px black;
}


.mode-btn {
    /* Make buttons take equal space */
    margin: 0 10px;
    /* 20px space between buttons */
    height: 300px;
    width: 200px !important;
}

.mode-btn ul {
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    width: 200px;
}

.game-start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card-hover-effect {
    transition: transform 0.3s ease;
  }
  
  .card-hover-effect:hover {
    transform: scale(0.95); /* Reduce the size to 95% of original when hovered */
  }

