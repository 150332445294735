.library-intro {
  background: rgb(255, 255, 255, 0.9);
  border-radius: 18px;
  font-family: "Mukta", sans-serif !important;
  font-size: 2vw;
}

.type-col {
  display: flex;
  justify-content: center;
  align-items: center;
}