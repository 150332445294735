.menu-col {
  width: 100%;
  display: flex;
  justify-content: center;
}

.menu-col div {
  width: 70%;
  height: 50%;
}

.menu-col div span {
  width: 100%;
  height: 100%;
}

.menu-col button {
  width: 100%;
  height: 100%;
}

.menu-col button .content p {
  font-size: 2vw;
  margin: 0;
  color: white;
}

.menu-col img {
  width: 50%;
  height: 50%;
}

.menu-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10; /* Increased to ensure it is on top */
}

.menu-head {
  text-shadow: 3px 4px black;
  color: white;
  height: 10%;
  text-align: center;
  margin-top: 5%;
  font-size: 3vw;
}

.game-intro {
  height: 50%;
  width: 70%;
  margin-left: 15%;
  transition: visibility 0.3s;
  color: white;
}
