.footer-ul {
    padding: 0;
}

.footer-ul li {
    list-style-type: none;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #4e4d4c;
}

.footer-ul li a{
    color: #4e4d4c;
    transition: color 0.5s;
}

.footer-ul li a:hover{
    color: #1677ff;
}
